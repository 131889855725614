import { render, staticRenderFns } from "./DayCardSkeleton.vue?vue&type=template&id=58d1150b&scoped=true&"
import script from "./DayCardSkeleton.vue?vue&type=script&lang=js&"
export * from "./DayCardSkeleton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d1150b",
  null
  
)

export default component.exports