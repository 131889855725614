import { render, staticRenderFns } from "./ClimbingForm2.vue?vue&type=template&id=fc1a6038&scoped=true&"
import script from "./ClimbingForm2.vue?vue&type=script&lang=js&"
export * from "./ClimbingForm2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc1a6038",
  null
  
)

export default component.exports