import { render, staticRenderFns } from "./DaySkeleton.vue?vue&type=template&id=052f0798&scoped=true&"
import script from "./DaySkeleton.vue?vue&type=script&lang=js&"
export * from "./DaySkeleton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "052f0798",
  null
  
)

export default component.exports