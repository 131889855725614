import { render, staticRenderFns } from "./RepsBadge.vue?vue&type=template&id=d89b348a&scoped=true&"
import script from "./RepsBadge.vue?vue&type=script&lang=js&"
export * from "./RepsBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d89b348a",
  null
  
)

export default component.exports